import { gql } from '@apollo/client';

export const UpdateEventMutation = gql`
  mutation ($id: ID!, $data: EventUpdateInput!) {
    updateEvent(where: { id: $id }, data: $data) {
      id
      twilioCallSid
      twilioCallResponse
      parking {
        id
        referenceParking {
          id
        }
        referencedBy {
          id
        }
      }
      user {
        email
      }
      updatedAt
      isAknowledgedByUser
      completedQuests {
        id
        createdAt
        quest {
          id
          title
          reward
          description
        }
      }
    }
  }
`;
